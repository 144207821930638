var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('footer', {
    staticClass: "footer",
    staticStyle: {
      "background": "#101527"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-lg-3 col-12 mb-0 mb-md-4 pb-0 pb-md-2"
  }), _c('div', {
    staticClass: "col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_c('h5', {
    staticClass: "footer-title text-white"
  }, [_vm._v(" " + _vm._s(_vm.$t("connect-with-us")) + " ")]), _c('span', {
    staticClass: "footer-title text-white"
  }, [_vm._v(" " + _vm._s(_vm.$t("phone")))]), _c('p', {
    staticClass: "text-secondary"
  }, [_vm._v("+603 6263 9990")]), _c('span', {
    staticClass: "footer-title text-white"
  }, [_vm._v(_vm._s(_vm.$t("address")))]), _vm._m(1), _c('span', {
    staticClass: "footer-title text-white"
  }, [_vm._v(_vm._s(_vm.$t("email")))]), _c('p', {
    staticClass: "text-secondary"
  }, [_vm._v("admin@awarisgroup.com")]), _c('ul', {
    staticClass: "list-unstyled social-icon social mb-0 mt-4"
  }, [_c('li', {
    staticClass: "list-inline-item"
  }, [_c('a', {
    staticClass: "rounded",
    attrs: {
      "href": "javascript:void(0)"
    }
  }, [_c('facebook-icon', {
    staticClass: "fea icon-sm fea-social"
  })], 1)]), _c('li', {
    staticClass: "list-inline-item ml-1"
  }, [_c('a', {
    staticClass: "rounded",
    attrs: {
      "href": "javascript:void(0)"
    }
  }, [_c('twitter-icon', {
    staticClass: "fea icon-sm fea-social"
  })], 1)]), _c('li', {
    staticClass: "list-inline-item ml-1"
  }, [_c('a', {
    staticClass: "rounded",
    attrs: {
      "href": "javascript:void(0)"
    }
  }, [_c('linkedin-icon', {
    staticClass: "fea icon-sm fea-social"
  })], 1)])])]), _c('div', {
    staticClass: "col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_c('h4', {
    staticClass: "footer-title text-white"
  }, [_vm._v(" " + _vm._s(_vm.$t("useful-links")) + " ")]), _c('ul', {
    staticClass: "list-unstyled footer-list mt-4"
  }, [_c('li', [_c('a', {
    staticClass: "text-foot",
    attrs: {
      "href": "https://awarisgroup.com/wp-content/uploads/2021/01/Terms-Conditions.pdf",
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("terms-conditions")))])]), _c('li', [_c('a', {
    staticClass: "text-foot",
    attrs: {
      "href": "https://awarisgroup.com/wp-content/uploads/2021/02/AWARIS-PRIVACY-POLICY.pdf",
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("privacy-policy")) + " ")])])]), _c('h4', {
    staticClass: "footer-title text-white",
    staticStyle: {
      "margin-top": "30px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("user-manual")) + " ")]), _c('ul', {
    staticClass: "list-unstyled footer-list mt-4"
  }, [['Staff', 'Super Admin', 'Admin', 'Agent'].includes(_vm.userRole) ? _c('li', [_c('a', {
    staticClass: "text-foot",
    attrs: {
      "href": "/files/Manual_Pendaftaran_Wasiat_Asas_(V2).pdf",
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("reg-basic-will-agent")) + " ")])]) : _vm._e(), ['Staff', 'Super Admin', 'Admin', 'Agent'].includes(_vm.userRole) ? _c('li', [_c('a', {
    staticClass: "text-foot",
    attrs: {
      "href": "/files/Independent_Flow_Manual_Customer.pdf",
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("independent-flow")))])]) : _vm._e(), ['Staff', 'Client'].includes(_vm.userRole) ? _c('li', [_c('a', {
    staticClass: "text-foot",
    attrs: {
      "href": "/files/Manual_Pindaan_Wasiat.pdf",
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("amendment-will-user")))])]) : _vm._e()])])])])]), _c('footer', {
    staticClass: "footer footer-bar"
  }, [_c('div', {
    staticClass: "container text-center"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-sm-12"
  }, [_c('div', {
    staticClass: "text-sm-center"
  }, [_c('p', {
    staticClass: "mb-0 footer-copyright"
  }, [_c('strong', [_vm._v("©2021 AWARIS. " + _vm._s(_vm.$t("copyright")) + ".")]), _vm._v(" " + _vm._s(_vm.$t("poweredby")) + " "), _c('strong', [_vm._v("PROOFFICE.")])])])])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-3 col-12 mb-0 mb-md-4 pb-0 pb-md-2"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-12"
  }, [_c('a', {
    staticClass: "logo-footer",
    attrs: {
      "href": "#"
    }
  }, [_c('img', {
    staticClass: "right",
    attrs: {
      "src": "/images/logo-awaris-white.png",
      "height": "50",
      "alt": ""
    }
  })])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-12"
  }, [_c('p', {
    staticClass: "footer-title",
    staticStyle: {
      "text-align": "right",
      "font-weight": "700"
    }
  }, [_vm._v(" GATEWAY FOR PRIVATE "), _c('br'), _vm._v(" INSTITUTIONAL TRUSTEES ")])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "text-secondary"
  }, [_vm._v(" M2-12-03, 8trium, Menara 2"), _c('br'), _vm._v(" Jalan Cempaka SD 12/5"), _c('br'), _vm._v(" Bandar Sri Damansara"), _c('br'), _vm._v(" 52200 Kuala Lumpur ")]);
}]

export { render, staticRenderFns }